import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "@mui/joy";
import {
  ExpandableSidebar,
  Button,
  Input,
  base64ToBlob,
  uniqId,
  SelectOption,
  useCall,
  Typography,
  globalStyles,
  clsx,
  useBoolean,
  imageSourceToCanvas,
  CanvasDrawImageSource,
  loadImage,
  resizeImage,
  Canvas2d,
  Vec2,
  useDidUpdate,
  useDidUnmount,
  SvgIcon,
} from "@gemlightbox/core-kit";

import {
  postAIGenerateImageDescriptions,
  postClassifyImage,
  PostRemoveBackgroundResponseData,
  postRetouchAISingle,
  putAssignMediaToProduct,
} from "src/api";
import { ProductTypeSelect } from "./product-type-select";
import { useStores } from "src/hooks";
import { DeviceMetaDataModel, MediaType } from "src/models";
import {
  getAttributesForRequest,
  pushDataLayerEvent,
  pushMediaLocalSaveLayerEvent,
  removeImageBackground,
} from "src/utils";
import { ModalExtendedType } from "src/store";
import { MediaPreview } from "./media-preview";
import { getCategory, prepareBlink } from "./camera-preview-sidebar.utils";
import { SKUSelect } from "./sku-select";
import { CameraMediaType } from "../camera.types";
import { cameraStore } from "../camera.store";
import { changeDpiDataUrl } from "changedpi";
import { ReactComponent as InvalidDirectorySVG } from "src/external-ts/assets/images/camera/invalid-directory-icon.svg";

import styles from "./camera-preview-sidebar.module.css";
import { defaultMiniStorageSpace } from "../camera.constants";

export type CameraPreviewSidebarProps = ModalExtendedType<{
  mediaList: CameraMediaType[];
  onClose: VoidFunction;
  loading: boolean;
  captureMode: string;
}>;

export const CameraPreviewSidebar: React.FC<CameraPreviewSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const {
      localeStore,
      mediaStore,
      modalsStore,
      notificationStore,
      attributesStore,
      categoriesStore,
      userStore,
    } = useStores();
    const { mediaList, onClose, loading = true, captureMode } = options;
    const {
      isBurstMode: isVideoBurstMode,
      generateAIDescription,
      presetMode,
      videoAngle,
      bluetoothDeviceSelected,
      gemAIActive,
      dpi,
    } = cameraStore;
    const isBurstMode = captureMode === "video" ? isVideoBurstMode : true;

    const useInSaveBoolean = useBoolean();
    const useInBlinkBoolean = useBoolean();
    const useInRmBGBoolean = useBoolean();
    const useTooltipBoolean = useBoolean(false);
    const useAIHandleLoadingBoolean = useBoolean(true);
    const useDescAIHandleLoadingBoolean = useBoolean(true);

    const [finalMedias, setFinalMedias] = useState(mediaList);
    const [selectedSKUOption, setSelectedSKUOption] = useState<SelectOption<number> | undefined>();
    const [selectedPDTOption, setSelectedPDTOption] = useState<SelectOption<string> | undefined>();
    const [titleValue, setTitleValue] = useState("");
    const [descriptionValue, setDescriptionValue] = useState<string>("");
    const [noBGImageForBlink, setNoBGImageForBlink] = useState<{
      image: CanvasDrawImageSource;
      sizes: Omit<PostRemoveBackgroundResponseData, "base64_str" | "statusCode">;
      centerDiff: Vec2;
    } | null>(null);
    const [delList, setDelList] = useState<string[]>([]);
    const usePostAIGenerateImageDescriptionsCall = useCall(postAIGenerateImageDescriptions);
    usePostAIGenerateImageDescriptionsCall.onCallSuccess((data) => {
      setTitleValue(data.product_title);
      setDescriptionValue(data.gpt_description_1);
      setSelectedPDTOption({ label: data.jewelry_type, value: data.jewelry_type });
    });

    const usePostClassifyImageCall = useCall(postClassifyImage);
    usePostClassifyImageCall.onCallSuccess(async (data) => {
      const foundCategory = categoriesStore.defaultCategories.find(
        (category) => category.name === getCategory(data.predicted_class),
      );
      if (!foundCategory || !noBGImageForBlink) return;

      const blinkMedia = await prepareBlink(foundCategory, noBGImageForBlink);

      setFinalMedias((prevState) => prevState.concat(blinkMedia));
      useInBlinkBoolean.setFalsy();
    });

    useEffect(() => {
      setTimeout(() => {
        if (
          captureMode === "video" &&
          mediaStore.sidebarOpened &&
          (mediaStore.savedStatus == 1 || mediaStore.savedStatus == 3)
        ) {
          useTooltipBoolean.setTruthy();
        } else {
          useTooltipBoolean.setFalsy();
        }
      }, 300);
    }, [mediaStore.sidebarOpened, mediaStore.savedStatus]);

    const cleanup = () => {
      closePreUpload();
      mediaStore.setSidebarOpened(false);
      setTitleValue("");
      setDescriptionValue("");
      onClose();
      cameraStore.setGemcamPreviewVideo(null);
    };

    const handleConfirmClose = () => {
      notificationStore.open({
        title: localeStore.t('camera["camera-preview-sidebar"]["close-confirm"].title'),
        message: "",
        confirmText: localeStore.t('camera["camera-preview-sidebar"]["close-confirm"].confirm'),
        cancelText: localeStore.t('camera["camera-preview-sidebar"]["close-confirm"].cancel'),
        onOk: () => {
          handleClose();
        },
      });
    };

    // 关闭右侧预览弹窗
    const handleClose = () => {
      setClose();
    };

    // 预先上传视频
    const preUpload = async () => {
      const mediaToUpload = mediaList.filter((media) => !media.blinkId);
      const videoToUpload = mediaToUpload.filter((media) => media.element.tagName === "VIDEO");
      if (videoToUpload && videoToUpload.length <= 0) {
        return;
      }
      const filesPromises = videoToUpload.map(async (media) => {
        const contentType = "video/mp4";
        const blob = await fetch(media.element.src).then((res) => res.blob());
        const fileName = uniqId() + ".mp4";
        const file = new File([blob], fileName, { type: contentType });
        return {
          files: file,
          types:
            videoAngle === 360 && bluetoothDeviceSelected?.name
              ? MediaType.video360
              : MediaType.video,
        };
      });

      const files = await Promise.all(filesPromises);
      const data = mediaStore.preUploadFiles.length
        ? mediaStore.preUploadFiles.concat(files)
        : [files];

      pushDataLayerEvent({
        event: "gemhub:camera:media:preupload:started",
        event_params: {
          media_filenames: files.map((f) => f.files.name),
        },
      });

      mediaStore.setPreUploadFiles(data);
      mediaStore.uploadMediaSync(files);
      mediaStore.uploadRequest?.fetch();
    };

    // 中断视频上传
    const closePreUpload = () => {
      if (mediaStore.savedStatus === 0) {
        mediaStore.uploadRequest?.abort();
      }
    };

    const isLocalStorageSettingsAvailable = (blob?: Blob | string) => {
      if (
        cameraStore.localSaveSettings.isLocalSaveMode &&
        !cameraStore.isLocalStorageSettingsAvailable(blob)
      ) {
        const param = {
          base_path: cameraStore.localSaveSettings?.folderPath,
          reserved_disk_space: cameraStore.localSaveSettings?.miniStorageSpace,
          reserved_disk_space_unit: cameraStore.localSaveSettings?.storageSpaceUnit,
        };
        if (!cameraStore.driveInfo) {
          modalsStore.open("CustomNotificationModal", {
            icon: <SvgIcon icon={InvalidDirectorySVG} size={34} />,
            title: localeStore.t(
              'camera["local-save-settings-modal"].notification["error-location"]',
            ),
            message: localeStore.t(
              'camera["local-save-settings-modal"].notification["message-save"]',
            ),
            confirmAppearance: "primary",
            confirmText: localeStore.t('camera["local-save-settings-modal"].notification.ok'),
          });
          pushMediaLocalSaveLayerEvent("saveMediaError", {
            ...param,
            error_message: localeStore.t(
              'camera["local-save-settings-modal"].notification["error-location"]',
            ),
          });
        } else {
          modalsStore.open("CustomNotificationModal", {
            icon: <SvgIcon icon={InvalidDirectorySVG} size={34} />,
            title: localeStore.t('camera["local-save-settings-modal"].notification["error-space"]'),
            message: localeStore.t(
              'camera["local-save-settings-modal"].notification["message-save"]',
            ),
            confirmAppearance: "primary",
            confirmText: localeStore.t('camera["local-save-settings-modal"].notification.ok'),
          });
          pushMediaLocalSaveLayerEvent("saveMediaError", {
            ...param,
            error_message: localeStore.t(
              'camera["local-save-settings-modal"].notification["error-space"]',
            ),
          });
        }
        cameraStore.setIsLocalSaveMode(false);
        return false;
      }
      return true;
    };

    const saveLocalStorage = (fileName: string, blob: Blob | string, sku?: string) => {
      const param = {
        base_path: cameraStore.localSaveSettings?.folderPath,
        reserved_disk_space: !cameraStore.localSaveSettings?.useCustom
          ? cameraStore.localSaveSettings?.miniStorageSpace
          : defaultMiniStorageSpace.miniStorageSpace,
        reserved_disk_space_unit: !cameraStore.localSaveSettings?.useCustom
          ? cameraStore.localSaveSettings?.storageSpaceUnit
          : defaultMiniStorageSpace.Unit,
        use_custom_reserved_disk_space: !!cameraStore.localSaveSettings?.useCustom,
        local_media_filenames: fileName,
        product_sku: sku,
      };

      const isAvailable = isLocalStorageSettingsAvailable(blob);
      if (isAvailable) {
        cameraStore.saveLocalStorage(
          fileName,
          blob,
          () => pushMediaLocalSaveLayerEvent("saveMediaSuccess", param),
          (msg) => {
            console.warn(msg);
            if (cameraStore.localSaveSettings.isLocalSaveMode) {
              modalsStore.open("CustomNotificationModal", {
                icon: <SvgIcon icon={InvalidDirectorySVG} size={34} />,
                title: localeStore.t(
                  'camera["local-save-settings-modal"].notification["error-location"]',
                ),
                message: localeStore.t(
                  'camera["local-save-settings-modal"].notification["message-save"]',
                ),
                confirmAppearance: "primary",
                confirmText: localeStore.t('camera["local-save-settings-modal"].notification.ok'),
                onOk: () => cameraStore.setIsLocalSaveMode(false),
              });

              pushMediaLocalSaveLayerEvent("saveMediaError", {
                ...param,
                error_message: msg,
              });
            }
          },
        );
      }
    };

    const handleSave = async () => {
      await cameraStore.getDriveInfo();
      useInSaveBoolean.setTruthy();

      const blinkMedia = finalMedias.find((media) => media.blinkId);

      let mediaToUpload = finalMedias.filter((media) => !media.blinkId);

      if (delList.length > 0) {
        mediaToUpload = mediaToUpload.filter((item) => {
          return !delList.includes(item.element.src);
        });
      }
      const filesPromise = mediaToUpload.map(async (media) => {
        const contentType = media.element.tagName === "IMG" ? "image/png" : "video/mp4";
        const fileExtension = media.element.tagName === "IMG" ? ".png" : ".mp4";
        const mediaType =
          media.element.tagName === "IMG"
            ? MediaType.image
            : videoAngle === 360 && bluetoothDeviceSelected?.name
            ? MediaType.video360
            : MediaType.video;

        const locaFileName =
          (selectedSKUOption?.value ? selectedSKUOption.label + "_" : "") + media.uuId;
        let blob: Blob;
        if (media.element.tagName === "IMG") {
          blob = base64ToBlob(media.element.src.split(",")[1], contentType);
          const originalMedia = mediaList.find(
            (f) => f.uuId === media.uuId && f.previewSrc !== media.previewSrc,
          );
          if (originalMedia)
            saveLocalStorage(
              `${locaFileName}.${originalMedia.processd}${fileExtension}`,
              originalMedia.previewSrc,
              selectedSKUOption?.label,
            );

          saveLocalStorage(
            `${locaFileName}.${media.processd}${fileExtension}`,
            media.previewSrc,
            selectedSKUOption?.label,
          );
        } else {
          blob = await fetch(media.element.src).then((res) => res.blob());
          saveLocalStorage(
            `${locaFileName}.${media.processd}${fileExtension}`,
            blob,
            selectedSKUOption?.label,
          );
        }

        const fileName = `${media.uuId}.${media.processd}${fileExtension}`;
        const file = new File([blob], fileName, { type: contentType });

        const metaData: DeviceMetaDataModel = {
          deviceName: cameraStore?.usbDevice?.cameraName,
          manufacturer: cameraStore?.usbDevice?.cameraManufacturer,
          serialNumber: cameraStore?.usbDevice?.cameraSerialNumber,
          isAiBackgroundRemoval: media.aiHandling === "AiBackgroundRemoval",
          isAiRetouch: media.aiHandling === "AiRetouch",
        };

        return {
          files: file,
          types: mediaType,
          sku: selectedSKUOption?.value,
          productType: selectedPDTOption?.value,
          title: titleValue,
          description: descriptionValue,
          metaData: metaData,
        };
      });

      try {
        if (blinkMedia?.blinkId && selectedSKUOption?.value) {
          await putAssignMediaToProduct
            .getRequest({
              params: {
                productId: selectedSKUOption.value,
              },
              queryParams: blinkMedia.blinkId.toString(),
            })
            .fetch();
        }
        const files = await Promise.all(filesPromise);
        pushDataLayerEvent({
          event: "click:camera:preview:save",
          event_params: {
            product_sku: files[0]?.sku,
            product_type: files[0]?.productType,
            media_filenames: (mediaStore.preUploadFiles.length
              ? mediaStore.preUploadFiles[0].concat(files.filter((d) => !d.types.includes("video")))
              : files
            ).map((f: { files: { name: any } }) => f.files.name),
          },
        });
        if (captureMode === "video") {
          mediaStore.setSavedPostUploadMedia(files);
          mediaStore.setSavedTitleValue(titleValue);
          mediaStore.setSavedDescriptionValue(descriptionValue);
          mediaStore.setSavedProductType(selectedPDTOption?.value);
          mediaStore.setSavedSelectedSKUOption(selectedSKUOption?.value);
          mediaStore.setSavedStatus(1);
          handleClose();
          useInSaveBoolean.setFalsy();
          if (mediaStore.preUploadStatus === 0 || mediaStore.preUploadStatus === 3) {
            mediaStore.setSavedProgress(0);
            preUpload();
          } else if (mediaStore.preUploadStatus === 2) {
            mediaStore.handleSave();
          }
        } else {
          pushDataLayerEvent({
            event: "gemhub:camera:media:cloud_save:started",
            event_params: {
              product_sku: files[0]?.sku,
              product_type: files[0]?.productType,
              media_filenames: files.map((f) => f.files.name),
              run_in_background: false,
            },
          });
          const parameters = getAttributesForRequest(
            {
              detailedTitle: titleValue || "",
              description: descriptionValue || "",
              productType: selectedPDTOption?.value || "",
            },
            attributesStore.attributes,
          ).filter((item) => item.value);
          mediaStore
            .uploadMedia(
              files,
              {},
              {
                description: descriptionValue,
                detailedTitle: titleValue,
                parameters,
              },
            )
            .then((res) => {
              if (res.status === "success") {
                pushDataLayerEvent({
                  event: "gemhub:camera:media:cloud_save:success",
                  event_params: {
                    product_sku: files[0]?.sku,
                    product_type: files[0]?.productType,
                    media_filenames: files.map((f) => f.files.name),
                  },
                });
                handleClose();
              } else {
                pushDataLayerEvent({
                  event: "gemhub:camera:media:cloud_save:error",
                  event_params: {
                    product_sku: files[0]?.sku,
                    product_type: files[0]?.productType,
                    media_filenames: files.map((f) => f.files.name),
                    http_status_code: res.details.statusCode,
                    error_message: res.error,
                  },
                });
                if (res.error?.originalError.code !== "limit:storage_exceeded") {
                  notificationStore.open({
                    title: "Saving failed, please retry",
                    confirmText: "RETRY",
                    cancelText: "",
                    confirmAppearance: "secondary",
                    icon: "exclamation",
                    onlyConfirm: true,
                  });
                }
              }
              useInSaveBoolean.setFalsy();
            });
        }
      } catch (e) {
        console.error(e);
        notificationStore.open({
          title: localeStore.t('camera["camera-preview-sidebar"]["error-popup"].title'),
          confirmText: localeStore.t('camera["camera-preview-sidebar"]["error-popup"]["ok-text"]'),
          cancelText: "",
          confirmAppearance: "secondary",
          icon: "exclamation",
          onlyConfirm: true,
        });
        useInSaveBoolean.setFalsy();
      }
    };

    const handleSKUChange = (value: SelectOption<number> | undefined) => {
      setSelectedSKUOption(value);
    };

    const handlePDTChange = (value: SelectOption<string> | undefined) => {
      setSelectedPDTOption(value);
    };

    const handleTitleChange = (value: string) => setTitleValue(value);

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescriptionValue(e.target.value);
    };

    const videoThumbnailBase64 = useMemo(() => {
      const media = mediaList.find((media) =>
        captureMode === "video" ? media.element.tagName !== "IMG" : media.element.tagName === "IMG",
      );
      if (!media) return null;
      return media.previewSrc;
    }, [mediaList]);

    const delayPromise = async (item: CameraMediaType) => {
      return new Promise<CameraMediaType>((resolve, reject) => {
        setTimeout(() => {
          const func = (media: CameraMediaType) =>
            removeImageBackground(media.element, { directRequest: true })
              .then(async ({ image }) => {
                const canvasImg = imageSourceToCanvas(image);

                const canvas2d = new Canvas2d({
                  size: { width: image.width, height: image.height },
                });

                canvas2d.drawRect({
                  x: 0,
                  y: 0,
                  width: canvas2d.width,
                  height: canvas2d.height,
                  fill: "white",
                });

                canvas2d.drawImage(canvasImg);
                const logoWatermarkBase64 = await cameraStore.setLogoWatermarkToImage(
                  canvas2d.toDataURL(),
                );
                const base64 = dpi
                  ? changeDpiDataUrl(logoWatermarkBase64, dpi)
                  : logoWatermarkBase64;
                return base64;
              })
              .then(async (base64) => {
                const image = await loadImage(base64);
                resolve({
                  element: image,
                  previewSrc: base64,
                  uuId: media.uuId,
                  processd: "background",
                  aiHandling: "AiBackgroundRemoval",
                });
              })
              .catch((err) => {
                console.error("remove bg error: ", err);
                reject(media);
              });

          return func(item);
        }, 50);
      });
    };

    const handleRemoveMediaThumbnail = async () => {
      //mounting if in model image mode
      if (!isBurstMode || presetMode !== "model" || !videoThumbnailBase64) return;

      useInBlinkBoolean.setTruthy();

      const mediaThumbnail = await loadImage(videoThumbnailBase64, true);

      const noBGImage = await removeImageBackground(mediaThumbnail, {
        cropped: true,
        directRequest: true,
      });

      setNoBGImageForBlink(noBGImage);

      const noBGCanvas = imageSourceToCanvas(noBGImage.image);

      const noBGCanvasBase64 = dpi
        ? changeDpiDataUrl(noBGCanvas.toDataURL(), dpi)
        : noBGCanvas.toDataURL();
      const blob = base64ToBlob(noBGCanvasBase64.split(",")[1], "image/jpeg");
      const fileName = uniqId() + ".jpeg";
      const file = new File([blob], fileName, { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("file", file);

      return usePostClassifyImageCall.submit({ data: formData });
    };

    const handleRemoveMediaListBg = async () => {
      //mounting if in remove background mode
      if (!isBurstMode || presetMode !== "background") return;

      useInRmBGBoolean.setTruthy();
      const medias = mediaList.filter((media) => media.element.tagName === "IMG");
      if (!medias.length) return;

      const removeBGPromises = [];
      let isRemoveBackgroundError = false;
      for (const item of medias) {
        removeBGPromises.push(
          delayPromise(item).catch((originalImage) => {
            isRemoveBackgroundError = true;
            return originalImage;
          }),
        );
      }

      try {
        const res = await Promise.all(removeBGPromises);

        setFinalMedias((prevState) => {
          useInRmBGBoolean.setFalsy();
          return prevState
            .filter((prevItem) => prevItem.element.tagName !== "IMG")
            .concat(res as any);
        });
      } catch (error) {
        console.error(error);
        isRemoveBackgroundError = true;
      }
      if (isRemoveBackgroundError)
        notificationStore.open({
          title: localeStore.t('camera["camera-preview-sidebar"]["error-remove-bg"].title'),
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: "",
          confirmAppearance: "secondary",
          icon: "exclamation",
          onlyConfirm: true,
        });

      return;
    };

    const handleRetouchAI = async () => {
      //mounting if in ai retouch mode
      if (!isBurstMode || presetMode !== "retouch") return;

      const medias = mediaList.filter((media) => media.element.tagName === "IMG");
      if (!medias.length) return;
      useInRmBGBoolean.setTruthy();
      const retouchPromises = medias.map((media) => {
        const oldCanvas = imageSourceToCanvas(media.element);
        const base64 = oldCanvas.toDataURL();
        const blob = base64ToBlob(base64.split(",")[1], "image/png");

        const formData = new FormData();
        formData.append("files", blob);

        return postRetouchAISingle
          .getRequest({ data: formData })
          .fetch()
          .then(async (result) => {
            let retouchedImg: CanvasDrawImageSource = await loadImage(
              `data:image/png;base64,${result.success}`,
              true,
            );

            // NOTE: retouch AI is limited to 1536x1536 maximum resolution,
            // so resize image to source size before actual retouching
            if (
              retouchedImg.width !== oldCanvas.width ||
              retouchedImg.height !== oldCanvas.height
            ) {
              retouchedImg = resizeImage(retouchedImg, oldCanvas.width, oldCanvas.height);
            }
            const retouchedCanvas = imageSourceToCanvas(retouchedImg);
            const logoWatermarkBase64 = await cameraStore.setLogoWatermarkToImage(
              retouchedCanvas.toDataURL(),
            );
            const base64 = dpi ? changeDpiDataUrl(logoWatermarkBase64, dpi) : logoWatermarkBase64;
            const retouchedElement = await loadImage(base64, true);
            return {
              element: retouchedElement,
              previewSrc: base64,
              aiHandling: "AiRetouch",
              uuId: media.uuId,
              processd: "retouch",
            } as CameraMediaType;
          })
          .catch(() => {
            useInRmBGBoolean.setFalsy();
            return media;
          });
      });

      try {
        const res = await Promise.all<CameraMediaType>(retouchPromises);
        useInRmBGBoolean.setFalsy();
        setFinalMedias((prevState) => {
          return prevState
            .filter((prevItem) => prevItem.element.tagName !== "IMG")
            .concat(res as any);
        });
      } catch (error) {
        console.error(error);
      }

      return;
    };

    const handleDescriptionAI = async () => {
      if (!isBurstMode || !generateAIDescription || titleValue || !videoThumbnailBase64) return;

      const blob = base64ToBlob(videoThumbnailBase64.split(",")[1], "image/jpeg");
      const fileName = uniqId() + ".jpeg";
      const file = new File([blob], fileName, { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("files", file);
      formData.append("use_gpt", "true");
      formData.append("language", userStore.aiLanguage);

      usePostAIGenerateImageDescriptionsCall.submit({ data: formData });

      return;
    };

    const onDeleteFn = (imageSrc: string) => {
      setDelList([...delList, imageSrc]);
    };

    useDidUpdate(
      () => {
        if (!loading && videoThumbnailBase64) {
          setFinalMedias(() => mediaList);
          if (mediaStore.savedStatus === 0 || mediaStore.savedStatus === 2) {
            preUpload();
          }
          if (!gemAIActive || !userStore.isSubscribed) {
            useAIHandleLoadingBoolean.setFalsy();
            useDescAIHandleLoadingBoolean.setFalsy();
            return;
          }
          Promise.all([
            handleRemoveMediaThumbnail(),
            handleRemoveMediaListBg(),
            handleRetouchAI(),
            handleDescriptionAI().finally(() => {
              useDescAIHandleLoadingBoolean.setFalsy();
            }),
          ])
            .then(() => {
              useAIHandleLoadingBoolean.setFalsy();
            })
            .catch(() => {
              useAIHandleLoadingBoolean.setFalsy();
            });
        }
      },
      [loading, videoThumbnailBase64],
      true,
    );

    useDidUnmount(() => {
      cleanup();
    });

    const saveButtonLoading =
      usePostAIGenerateImageDescriptionsCall.submitting ||
      useInSaveBoolean.value ||
      useInBlinkBoolean.value ||
      useAIHandleLoadingBoolean.value ||
      loading;

    const descAIHandleLoading =
      usePostAIGenerateImageDescriptionsCall.submitting ||
      (!!generateAIDescription && useDescAIHandleLoadingBoolean.value);

    return (
      <ExpandableSidebar
        portalClassName={styles.portal}
        contentClassName={styles.contentWrapper}
        sidebarHeaderClassName={styles.headerContainer}
        sidebarContentClassName={styles.contentContainer}
        sidebarFooterClassName={styles.footerContainer}
        iconPos="outside"
        title={localeStore.t('camera["camera-preview-sidebar"].title')}
        isOpen={isOpen}
        onFinalClosed={onFinalClosed}
        footer={
          <div className={styles.footer}>
            <Button
              appearance="tertiaryOutlined"
              size="large"
              onClick={handleConfirmClose}
              disabled={useInSaveBoolean.value}
            >
              {localeStore.t('camera["camera-preview-sidebar"].buttons.cancel')}
            </Button>

            <Tooltip
              variant="solid"
              id="save-btn-tooltip"
              sx={{
                width: "233px",
                fontSize: "13px",
                textAlign: "center",
                background: "#363E5C",
                borderRadius: "4px",
                padding: "10px",
              }}
              title={
                <div className="save-btn-tooltip">
                  <span
                    className="close"
                    onClick={() => {
                      useTooltipBoolean.setFalsy();
                    }}
                  >
                    &times;
                  </span>
                  {mediaStore.savedStatus === 1 &&
                    localeStore.t('camera["save-background"]["save-tip"]')}
                  {mediaStore.savedStatus === 3 &&
                    localeStore.t('camera["save-background"]["error-tip"]')}
                </div>
              }
              arrow
              open={
                useTooltipBoolean.value &&
                (mediaStore.savedStatus === 1 || mediaStore.savedStatus === 3)
              }
              placement="top"
            >
              <div style={{ width: "100%" }}>
                <Button
                  size="large"
                  onClick={handleSave}
                  className={
                    mediaStore.savedStatus === 1 || mediaStore.savedStatus === 3
                      ? "save-btn-disabled"
                      : ""
                  }
                  loading={saveButtonLoading}
                >
                  {localeStore.t('camera["camera-preview-sidebar"].buttons.save')}
                </Button>
              </div>
            </Tooltip>
          </div>
        }
        disableBackdropClose={true}
      >
        <div className={styles.content}>
          <MediaPreview
            mediaList={finalMedias}
            onDelete={onDeleteFn}
            setClose={setClose}
            hideAddButton={true}
            showLoadingPreview={useInBlinkBoolean.value}
            showLoadingRmBG={useInRmBGBoolean.value}
          />
          <div className={styles.formItemWrap}>
            <SKUSelect selectedSKUOption={selectedSKUOption} onSKUChange={handleSKUChange} />
          </div>

          <div className={styles.formItemWrap}>
            {descAIHandleLoading && (
              <div className={styles.loadingBox}>
                <span className={styles.loadingPoint}></span>
                <span className={styles.loadingPoint}></span>
                <span className={styles.loadingPoint}></span>
              </div>
            )}

            <ProductTypeSelect
              disabled={descAIHandleLoading}
              selectedOption={selectedPDTOption}
              onChange={handlePDTChange}
            ></ProductTypeSelect>
          </div>

          <div className={styles.formItemWrap}>
            {descAIHandleLoading && (
              <div className={styles.loadingBox}>
                <span className={styles.loadingPoint}></span>
                <span className={styles.loadingPoint}></span>
                <span className={styles.loadingPoint}></span>
              </div>
            )}
            <Input
              appearance="primaryV2"
              label={localeStore.t('camera["camera-preview-sidebar"]["title-field"].label')}
              placeholder={
                !descAIHandleLoading
                  ? localeStore.t('camera["camera-preview-sidebar"]["title-field"].placeholder')
                  : ""
              }
              value={titleValue}
              onChange={handleTitleChange}
              disableError
              data-hj-allow
            />
          </div>

          <div className={styles.formItemWrap}>
            {descAIHandleLoading && (
              <div className={styles.loadingBox}>
                <span className={styles.loadingPoint}></span>
                <span className={styles.loadingPoint}></span>
                <span className={styles.loadingPoint}></span>
              </div>
            )}
            <label className={styles.descriptionLabel}>
              <Typography size="extraSmall" color="textSecondary">
                {localeStore.t('camera["camera-preview-sidebar"]["description-field"].label')}
              </Typography>
            </label>
            <textarea
              className={clsx(styles.descriptionField, globalStyles.addScrollStyles)}
              id="description"
              placeholder={
                !descAIHandleLoading
                  ? localeStore.t(
                      'camera["camera-preview-sidebar"]["description-field"].placeholder',
                    )
                  : ""
              }
              value={descriptionValue}
              onChange={handleDescriptionChange}
              data-hj-allow
            />
          </div>
        </div>
      </ExpandableSidebar>
    );
  },
);
